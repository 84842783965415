
import { DateTime, DurationUnit } from "luxon";
import Vue from "vue";
import "ag-grid-enterprise";
import { ColDef, LicenseManager } from "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

LicenseManager.setLicenseKey(
  "DownloadDevTools_COM_NDEwMjM0NTgwMDAwMA==59158b5225400879a12a96634544f5b6"
);

export default Vue.extend({
  name: "InternalAllVehicles",
  components: {
    AgGridVue
  },
  data() {
    return {
      gridProps: {
        columnDefs: undefined as ColDef[] | undefined,
        rowData: undefined as any,
        defaultColDef: {
          resizable: true,
          sortable: true
        },
        gridApi: undefined as any,
        gridColumnApi: undefined as any
      },
      tableProps: {
        loading: false,
        defaultSortDirection: "desc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small"
      },
      filters: {
        categoryQuery: undefined,
        showVehiclesNotUpdatedIn24h: false,
        dongleStatus: undefined
      },
      selected: undefined
    };
  },
  computed: {
    allVehiclesSummary() {
      return this.$store.state.internalDiagnosticsAllVehicles;
    }
  },
  created() {
    this.getData();
    this.gridProps.columnDefs = [
      {
        headerName: "Nr.",
        valueGetter: "node.rowIndex + 1"
      },
      {
        field: "companyName",
        headerName: "Customer",
        valueGetter: params => {
          return `${params.data.companyName}\r\n(${params.data.fleetCategory?.name})`;
        },
        cellRenderer: params => {
          return `${params.data.companyName}
          <br/>
          (${params.data.fleetCategory?.name})`;
        },
        autoHeight: true
      },
      {
        field: "vehicleRegistrationNo",
        headerName: "Vehicle",
        valueGetter: params => {
          return params.data.vehicleRegistrationNo;
        }
      },
      {
        field: "updatedAt",
        headerName: "Location Updated At",
        valueGetter: params => {
          return `${dayjs(params.data.updatedAt).fromNow()}\n${dayjs(
            params.data.updatedAt
          ).format("DD MMM YYYY, hh:mm:ss A")}`;
        },
        cellRenderer: params => {
          return `${dayjs(params.data.updatedAt).fromNow()} <br/> ${dayjs(
            params.data.updatedAt
          ).format("DD MMM YYYY, hh:mm:ss A")}`;
        },
        autoHeight: true,
        comparator: (date1, date2, node1, node2, isDescending) => {
          const d1 = dayjs(node1.data.updatedAt);
          const d2 = dayjs(node2.data.updatedAt);
          const isD2BeforeD1 = d2.isBefore(d1);
          if (isDescending) {
            if (isD2BeforeD1) {
              return -1;
            } else {
              return 1;
            }
          }
          if (!isDescending) {
            if (isD2BeforeD1) {
              return -1;
            } else {
              return 1;
            }
          }
        }
      },
      {
        field: "dongleBattVoltage",
        headerName: "Batt Voltage",
        valueGetter: params => {
          return `${(params.data.dongleBattVoltage / 1000).toFixed(3)} V`;
        },
        cellRenderer: params => {
          return (
            `${(params.data.dongleBattVoltage / 1000).toFixed(3)} V` +
            "<br>" +
            `${dayjs(params.data.dongleBattVoltageRecordedAt).format(
              "DD MMM YYYY, hh:mm:ss A"
            )}`
          );
        },
        cellStyle: {
          display: "flex",
          alignItems: "center"
        },
        autoHeight: true
      },
      {
        field: "dongleId",
        headerName: "Dongle #"
      },
      {
        field: "dongleStatus",
        headerName: "Connected?",
        valueGetter: params => {
          return params.data.dongleStatus?.toLowerCase()
        },
        cellStyle: params => {
          if (params.data.dongleStatus === 'DISCONNECTED') {
            return { color: "#800000" };
          }
          if (params.data.dongleStatus === 'CONNECTED') {
            return { color: "#407e65" };
          }
          if (params.data.dongleStatus === 'INACTIVE') {
            return { color: "#c68037" };
          }
        }
      },
      {
        field: "vehicleState",
        headerName: "Veh State?",
        cellStyle: params => {
          if (params.data.vehicleState === "Parked") {
            return { color: "#c68037" };
          }
          if (params.data.vehicleState === "Moving") {
            return { color: "#407e65" };
          }
        }
      }
    ];
  },
  watch: {
    selectedTenant: function () {
      this.initializeView();
    }
  },
  methods: {
    onGridReady(params: any) {
      this.gridProps.gridApi = params.api;
      this.gridProps.gridColumnApi = params.columnApi;
      this.gridProps.gridColumnApi.autoSizeAllColumns();
    },
    removeSelection() {
      this.selected = undefined;
    },
    handleShowVehiclesNotUpdatedIn24hFilter() {
      this.filters.showVehiclesNotUpdatedIn24h = !this.filters
        .showVehiclesNotUpdatedIn24h;
      this.getData();
    },
    handleDongleConnectivityFilter() {
      this.getData();
    },
    handleResetFilter() {
      this.filters.showVehiclesNotUpdatedIn24h = false;
      this.filters.dongleStatus = undefined;
      this.getData();
    },
    async getVehiclesBehaviorReport() {
      this.tableProps.loading = true;

      await this.$store.dispatch("getVehiclesBehaviorStatus", {
        selectedTenant: this.selectedTenant
      });

      if (this.vehiclesBehavior) {
        this.tableProps.loading = false;
      }
    },
    async getData() {
      this.tableProps.loading = true;
      await this.$store.dispatch("getInternalDiagnosticsAllVehicles", {
        dongleStatus: this.filters.dongleStatus,
        showVehiclesNotUpdatedIn24h: this.filters.showVehiclesNotUpdatedIn24h
      });
      this.tableProps.loading = false;
    },
    timeAgo(date) {
      // source https://github.com/moment/luxon/issues/274#issuecomment-729126515
      const units = [
        "year",
        "month",
        "week",
        "day",
        "hour",
        "minute",
        "second"
      ] as DurationUnit[];

      const dateTime = DateTime.fromISO(date);
      const diff = dateTime.diffNow().shiftTo(...units);
      const unit = units.find(unit => diff.get(unit) !== 0) || "second";

      // todo: using any until we upgrade to TypeSript versin 4.2
      const relativeFormatter = new (Intl as any).RelativeTimeFormat("en", {
        numeric: "auto"
      });
      return relativeFormatter.format(Math.trunc(diff.as(unit)), unit as any);
    }
  }
});
